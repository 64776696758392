import routerOptions0 from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/app/router.options.ts";
const configRouterOptions = {
  scrollBehaviorType: "smooth",
  hashMode: false
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}