import type { Ref } from 'vue';

type ShowApiKeyPopupType = {
  isOpen: Ref<boolean>
  openShowApiKeyPopup: () => void;
  closeShowApiKeyPopup: () => void;
}

export const showApiKeyPopupStore = defineStore('ShowApiKeyPopup', (): ShowApiKeyPopupType => {
  const isOpen = ref<boolean>(false);
  const openShowApiKeyPopup = () => {
    isOpen.value = true;
  };
  const closeShowApiKeyPopup = () => {
    isOpen.value = false;
  }

  return {
    isOpen,
    openShowApiKeyPopup,
    closeShowApiKeyPopup
  };
});