<template>
  <footer
    class="footer"
    :class="{'userPagePadding': isUserCabinet}"
  >
    <div class="container">
      <div class="footer__contact">
        <NuxtLink to="/">
          <NuxtImg
            src="/img/logo-black.svg"
            alt="logo"
            height="40"
          />
        </NuxtLink>
        <div class="social">
          <Nuxt-link target="_blank" to="https://t.me/smscode_support">
            <NuxtImg
              src="/img/icon-tg.svg"
              alt="telegram"
              height="40"
              width="40"
            />
          </Nuxt-link>
          <Nuxt-link to="mailto:support@sms-code.ru">
            <NuxtImg
              src="/img/icon-google.svg"
              alt="google"
              height="40"
              width="40"
            />
          </Nuxt-link>
        </div>
      </div>

      <div class="footer__copy" v-if="false">
        <p>&copy; {{ nowDate }} {{ $t('copy') }}.</p>
      </div>

      <div class="footer__cookie">
        <nuxt-link
          :href="publicOfferLink"
          target="_blank"
        >
          {{ $t('policy') }}
        </nuxt-link>
      </div>
      <div class="footer__privacy-policy">
        <nuxt-link
          :href="privacyPolicyLink"
          target="_blank"
        >
          {{ $t('publicOffer')}}
        </nuxt-link>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const $route = useRoute();
const {
  locale,
  locales
} = useI18n();

const nowDate = computed(() => {
  const date = new Date();
  const yyyy = `${date.getFullYear()}`;
  return yyyy;
});

const publicOfferLink = computed(() => {
  return locale.value === "en" ? `files/EN_Public_Offer_SMSCODE.pdf` : 'files/RU_Public_Offer_SMSCODE.pdf'
})
const privacyPolicyLink = computed(() => {
  return locale.value === "en" ? 'files/EN_Privacy_Policy_SMSCODE.pdf' : 'files/RU_Privacy_Policy_SMSCODE.pdf'
})

const isUserCabinet = computed((): boolean => $route?.fullPath?.split('/')?.[1] === 'cabinet');
</script>

<style scoped lang="scss">
.footer {
  padding: 20px 0 24px;
  background-color: var(--white-light);
  font-size: 14px;
  font-style: italic;
  font-weight: 100;
  line-height: 1.85;
  color: var(--main-color);

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  &__contact {
    display: flex;
    align-items: center;
    gap: 54px;

    & > a:hover {
      opacity: 0.8;
    }

    .social {
      display: flex;
      align-items: center;
      gap: 70px;

      & > a {
        display: inline-block;
        & > img {
          width: 40px;
          height: 40px;
        }
      }

      & > a:hover {
        opacity: 0.8;
      }
    }
  }

  &__cookie {
    a {
      font-size: 14px;
      font-style: italic;
      font-weight: 100;
      line-height: 1.85;
      color: var(--main-color);

      &:hover {
        opacity: 0.8;
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &.userPagePadding {
      padding: 16px 0 20px 60px; /**@FIXME отступ для того, чтобы в мобилке футер не залазил в навбар */
    }

    .container {
      flex-direction: column;
    }

    &__contact {
      img {
        max-height: 24px;
      }
    }
  }

  @media screen and (max-width: 860px) {
    .footer__contact {
      flex-direction: column-reverse;
      gap: 20px;
      max-width: 126px;

      & .social {
        gap: 60px;

        & > a {
          & > img {
            width: 32px;
            height: 32px;
          }
        }

      }

      & img{
        max-height: 34px;

        &:first-child {
          width: 135px;
        }
      }
    }
    &__cookie {
      text-align: center;
    }
  }
}
</style>
