import { default as _91_46_46_46token_93Pl4eMBnfLWMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/authorization-social/[...token].vue?macro=true";
import { default as indexADu1SEvpVTMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/authorization/index.vue?macro=true";
import { default as indexayfRpPzLCWMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/cabinet/api/index.vue?macro=true";
import { default as index1PUS4YxwoxMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/cabinet/index.vue?macro=true";
import { default as indexvb4ZflSYwEMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/cabinet/numbers-history/index.vue?macro=true";
import { default as indexADEE1QWV88Meta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/cabinet/payments/index.vue?macro=true";
import { default as indexiTrGgLZwRkMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/cabinet/profile/index.vue?macro=true";
import { default as index9o7sNsu0OcMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/forgot-password/index.vue?macro=true";
import { default as indexnzsmUGDEO8Meta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/index.vue?macro=true";
import { default as indexs2izxLNeQ0Meta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/payment/result/index.vue?macro=true";
import { default as indexoaBMDVrHSrMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/registration/index.vue?macro=true";
import { default as _91_46_46_46token_931UUSdJI4V0Meta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/reset-password/[...token].vue?macro=true";
export default [
  {
    name: _91_46_46_46token_93Pl4eMBnfLWMeta?.name ?? "authorization-social-token___en___default",
    path: _91_46_46_46token_93Pl4eMBnfLWMeta?.path ?? "/authorization-social/:token(.*)*",
    meta: _91_46_46_46token_93Pl4eMBnfLWMeta || {},
    alias: _91_46_46_46token_93Pl4eMBnfLWMeta?.alias || [],
    redirect: _91_46_46_46token_93Pl4eMBnfLWMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/authorization-social/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93Pl4eMBnfLWMeta?.name ?? "authorization-social-token___en",
    path: _91_46_46_46token_93Pl4eMBnfLWMeta?.path ?? "/en/authorization-social/:token(.*)*",
    meta: _91_46_46_46token_93Pl4eMBnfLWMeta || {},
    alias: _91_46_46_46token_93Pl4eMBnfLWMeta?.alias || [],
    redirect: _91_46_46_46token_93Pl4eMBnfLWMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/authorization-social/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93Pl4eMBnfLWMeta?.name ?? "authorization-social-token___ru",
    path: _91_46_46_46token_93Pl4eMBnfLWMeta?.path ?? "/ru/authorization-social/:token(.*)*",
    meta: _91_46_46_46token_93Pl4eMBnfLWMeta || {},
    alias: _91_46_46_46token_93Pl4eMBnfLWMeta?.alias || [],
    redirect: _91_46_46_46token_93Pl4eMBnfLWMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/authorization-social/[...token].vue").then(m => m.default || m)
  },
  {
    name: indexADu1SEvpVTMeta?.name ?? "authorization___en___default",
    path: indexADu1SEvpVTMeta?.path ?? "/authorization",
    meta: indexADu1SEvpVTMeta || {},
    alias: indexADu1SEvpVTMeta?.alias || [],
    redirect: indexADu1SEvpVTMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexADu1SEvpVTMeta?.name ?? "authorization___en",
    path: indexADu1SEvpVTMeta?.path ?? "/en/authorization",
    meta: indexADu1SEvpVTMeta || {},
    alias: indexADu1SEvpVTMeta?.alias || [],
    redirect: indexADu1SEvpVTMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexADu1SEvpVTMeta?.name ?? "authorization___ru",
    path: indexADu1SEvpVTMeta?.path ?? "/ru/authorization",
    meta: indexADu1SEvpVTMeta || {},
    alias: indexADu1SEvpVTMeta?.alias || [],
    redirect: indexADu1SEvpVTMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexayfRpPzLCWMeta?.name ?? "cabinet-api___en___default",
    path: indexayfRpPzLCWMeta?.path ?? "/cabinet/api",
    meta: indexayfRpPzLCWMeta || {},
    alias: indexayfRpPzLCWMeta?.alias || [],
    redirect: indexayfRpPzLCWMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexayfRpPzLCWMeta?.name ?? "cabinet-api___en",
    path: indexayfRpPzLCWMeta?.path ?? "/en/cabinet/api",
    meta: indexayfRpPzLCWMeta || {},
    alias: indexayfRpPzLCWMeta?.alias || [],
    redirect: indexayfRpPzLCWMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexayfRpPzLCWMeta?.name ?? "cabinet-api___ru",
    path: indexayfRpPzLCWMeta?.path ?? "/ru/cabinet/api",
    meta: indexayfRpPzLCWMeta || {},
    alias: indexayfRpPzLCWMeta?.alias || [],
    redirect: indexayfRpPzLCWMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: index1PUS4YxwoxMeta?.name ?? "cabinet___en___default",
    path: index1PUS4YxwoxMeta?.path ?? "/cabinet",
    meta: index1PUS4YxwoxMeta || {},
    alias: index1PUS4YxwoxMeta?.alias || [],
    redirect: index1PUS4YxwoxMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: index1PUS4YxwoxMeta?.name ?? "cabinet___en",
    path: index1PUS4YxwoxMeta?.path ?? "/en/cabinet",
    meta: index1PUS4YxwoxMeta || {},
    alias: index1PUS4YxwoxMeta?.alias || [],
    redirect: index1PUS4YxwoxMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: index1PUS4YxwoxMeta?.name ?? "cabinet___ru",
    path: index1PUS4YxwoxMeta?.path ?? "/ru/cabinet",
    meta: index1PUS4YxwoxMeta || {},
    alias: index1PUS4YxwoxMeta?.alias || [],
    redirect: index1PUS4YxwoxMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexvb4ZflSYwEMeta?.name ?? "cabinet-numbers-history___en___default",
    path: indexvb4ZflSYwEMeta?.path ?? "/cabinet/numbers-history",
    meta: indexvb4ZflSYwEMeta || {},
    alias: indexvb4ZflSYwEMeta?.alias || [],
    redirect: indexvb4ZflSYwEMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexvb4ZflSYwEMeta?.name ?? "cabinet-numbers-history___en",
    path: indexvb4ZflSYwEMeta?.path ?? "/en/cabinet/numbers-history",
    meta: indexvb4ZflSYwEMeta || {},
    alias: indexvb4ZflSYwEMeta?.alias || [],
    redirect: indexvb4ZflSYwEMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexvb4ZflSYwEMeta?.name ?? "cabinet-numbers-history___ru",
    path: indexvb4ZflSYwEMeta?.path ?? "/ru/cabinet/numbers-history",
    meta: indexvb4ZflSYwEMeta || {},
    alias: indexvb4ZflSYwEMeta?.alias || [],
    redirect: indexvb4ZflSYwEMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexADEE1QWV88Meta?.name ?? "cabinet-payments___en___default",
    path: indexADEE1QWV88Meta?.path ?? "/cabinet/payments",
    meta: indexADEE1QWV88Meta || {},
    alias: indexADEE1QWV88Meta?.alias || [],
    redirect: indexADEE1QWV88Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexADEE1QWV88Meta?.name ?? "cabinet-payments___en",
    path: indexADEE1QWV88Meta?.path ?? "/en/cabinet/payments",
    meta: indexADEE1QWV88Meta || {},
    alias: indexADEE1QWV88Meta?.alias || [],
    redirect: indexADEE1QWV88Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexADEE1QWV88Meta?.name ?? "cabinet-payments___ru",
    path: indexADEE1QWV88Meta?.path ?? "/ru/cabinet/payments",
    meta: indexADEE1QWV88Meta || {},
    alias: indexADEE1QWV88Meta?.alias || [],
    redirect: indexADEE1QWV88Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexiTrGgLZwRkMeta?.name ?? "cabinet-profile___en___default",
    path: indexiTrGgLZwRkMeta?.path ?? "/cabinet/profile",
    meta: indexiTrGgLZwRkMeta || {},
    alias: indexiTrGgLZwRkMeta?.alias || [],
    redirect: indexiTrGgLZwRkMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexiTrGgLZwRkMeta?.name ?? "cabinet-profile___en",
    path: indexiTrGgLZwRkMeta?.path ?? "/en/cabinet/profile",
    meta: indexiTrGgLZwRkMeta || {},
    alias: indexiTrGgLZwRkMeta?.alias || [],
    redirect: indexiTrGgLZwRkMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexiTrGgLZwRkMeta?.name ?? "cabinet-profile___ru",
    path: indexiTrGgLZwRkMeta?.path ?? "/ru/cabinet/profile",
    meta: indexiTrGgLZwRkMeta || {},
    alias: indexiTrGgLZwRkMeta?.alias || [],
    redirect: indexiTrGgLZwRkMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index9o7sNsu0OcMeta?.name ?? "forgot-password___en___default",
    path: index9o7sNsu0OcMeta?.path ?? "/forgot-password",
    meta: index9o7sNsu0OcMeta || {},
    alias: index9o7sNsu0OcMeta?.alias || [],
    redirect: index9o7sNsu0OcMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: index9o7sNsu0OcMeta?.name ?? "forgot-password___en",
    path: index9o7sNsu0OcMeta?.path ?? "/en/forgot-password",
    meta: index9o7sNsu0OcMeta || {},
    alias: index9o7sNsu0OcMeta?.alias || [],
    redirect: index9o7sNsu0OcMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: index9o7sNsu0OcMeta?.name ?? "forgot-password___ru",
    path: index9o7sNsu0OcMeta?.path ?? "/ru/forgot-password",
    meta: index9o7sNsu0OcMeta || {},
    alias: index9o7sNsu0OcMeta?.alias || [],
    redirect: index9o7sNsu0OcMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexnzsmUGDEO8Meta?.name ?? "index___en___default",
    path: indexnzsmUGDEO8Meta?.path ?? "/",
    meta: indexnzsmUGDEO8Meta || {},
    alias: indexnzsmUGDEO8Meta?.alias || [],
    redirect: indexnzsmUGDEO8Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexnzsmUGDEO8Meta?.name ?? "index___en",
    path: indexnzsmUGDEO8Meta?.path ?? "/en",
    meta: indexnzsmUGDEO8Meta || {},
    alias: indexnzsmUGDEO8Meta?.alias || [],
    redirect: indexnzsmUGDEO8Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexnzsmUGDEO8Meta?.name ?? "index___ru",
    path: indexnzsmUGDEO8Meta?.path ?? "/ru",
    meta: indexnzsmUGDEO8Meta || {},
    alias: indexnzsmUGDEO8Meta?.alias || [],
    redirect: indexnzsmUGDEO8Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexs2izxLNeQ0Meta?.name ?? "payment-result___en___default",
    path: indexs2izxLNeQ0Meta?.path ?? "/payment/result",
    meta: indexs2izxLNeQ0Meta || {},
    alias: indexs2izxLNeQ0Meta?.alias || [],
    redirect: indexs2izxLNeQ0Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexs2izxLNeQ0Meta?.name ?? "payment-result___en",
    path: indexs2izxLNeQ0Meta?.path ?? "/en/payment/result",
    meta: indexs2izxLNeQ0Meta || {},
    alias: indexs2izxLNeQ0Meta?.alias || [],
    redirect: indexs2izxLNeQ0Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexs2izxLNeQ0Meta?.name ?? "payment-result___ru",
    path: indexs2izxLNeQ0Meta?.path ?? "/ru/payment/result",
    meta: indexs2izxLNeQ0Meta || {},
    alias: indexs2izxLNeQ0Meta?.alias || [],
    redirect: indexs2izxLNeQ0Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexoaBMDVrHSrMeta?.name ?? "registration___en___default",
    path: indexoaBMDVrHSrMeta?.path ?? "/registration",
    meta: indexoaBMDVrHSrMeta || {},
    alias: indexoaBMDVrHSrMeta?.alias || [],
    redirect: indexoaBMDVrHSrMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexoaBMDVrHSrMeta?.name ?? "registration___en",
    path: indexoaBMDVrHSrMeta?.path ?? "/en/registration",
    meta: indexoaBMDVrHSrMeta || {},
    alias: indexoaBMDVrHSrMeta?.alias || [],
    redirect: indexoaBMDVrHSrMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexoaBMDVrHSrMeta?.name ?? "registration___ru",
    path: indexoaBMDVrHSrMeta?.path ?? "/ru/registration",
    meta: indexoaBMDVrHSrMeta || {},
    alias: indexoaBMDVrHSrMeta?.alias || [],
    redirect: indexoaBMDVrHSrMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_931UUSdJI4V0Meta?.name ?? "reset-password-token___en___default",
    path: _91_46_46_46token_931UUSdJI4V0Meta?.path ?? "/reset-password/:token(.*)*",
    meta: _91_46_46_46token_931UUSdJI4V0Meta || {},
    alias: _91_46_46_46token_931UUSdJI4V0Meta?.alias || [],
    redirect: _91_46_46_46token_931UUSdJI4V0Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/reset-password/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_931UUSdJI4V0Meta?.name ?? "reset-password-token___en",
    path: _91_46_46_46token_931UUSdJI4V0Meta?.path ?? "/en/reset-password/:token(.*)*",
    meta: _91_46_46_46token_931UUSdJI4V0Meta || {},
    alias: _91_46_46_46token_931UUSdJI4V0Meta?.alias || [],
    redirect: _91_46_46_46token_931UUSdJI4V0Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/reset-password/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_931UUSdJI4V0Meta?.name ?? "reset-password-token___ru",
    path: _91_46_46_46token_931UUSdJI4V0Meta?.path ?? "/ru/reset-password/:token(.*)*",
    meta: _91_46_46_46token_931UUSdJI4V0Meta || {},
    alias: _91_46_46_46token_931UUSdJI4V0Meta?.alias || [],
    redirect: _91_46_46_46token_931UUSdJI4V0Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241213100433/pages/reset-password/[...token].vue").then(m => m.default || m)
  }
]