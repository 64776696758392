import type { Ref } from 'vue';

export type SuccessPopupOptions = {
  title: string | null;
  message: string | null;
}

type SuccessPopupType = {
  isOpen: Ref<boolean>;
  internalOptions: Ref<SuccessPopupOptions | null>;
  openSuccessPopup: (options: SuccessPopupOptions | null) => void;
  closeSuccessPopup: () => void;
}

export const successPopupStore = defineStore('successPopup', (): SuccessPopupType => {
  const isOpen = ref<boolean>(false);
  const internalOptions = ref<SuccessPopupOptions | null>(null)
  const openSuccessPopup = (options: SuccessPopupOptions | null) => {
    if (options?.title || options?.message) {
      internalOptions.value = options;
      isOpen.value = true;
    }
    if (!options) {
      internalOptions.value = null;
      isOpen.value = true;
    }
  };
  const closeSuccessPopup = () => {
    isOpen.value = false;
    internalOptions.value = null;
  }

  return {
    isOpen,
    internalOptions,
    openSuccessPopup,
    closeSuccessPopup
  };
});
